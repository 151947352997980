var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":{ name: "purchase-orders-new" }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create New Purchase Order")],1)],1),(_vm.isDialogOpen)?_c('dialog-component',{on:{"close":_vm.closeDialog},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Purchase Order")]},proxy:true},{key:"body",fn:function(){return [_vm._v(_vm._s(_vm.dialogBodyText))]},proxy:true},(_vm.allowedToDelete)?{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.deletePurchaseOrder}},[_vm._v("Delete")])]},proxy:true}:null],null,true)}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.projects,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading Purchase Orders","no-results-text":"No Matching Purchase Orders Found for " + _vm.search,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"click:row":_vm.selectProject,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.updatedAtDate)))]}},{key:"item.archived",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setArchiveStatus(item)}},model:{value:(item.archived),callback:function ($$v) {_vm.$set(item, "archived", $$v)},expression:"item.archived"}})]}},{key:"item.status.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-container"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.status.name))]),_c('v-menu',{staticClass:"menu",attrs:{"left":"","transition":"expand-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"light_blue"}},[_vm._v("mdi-delete")]),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }