




import Vue from 'vue';

export default Vue.extend({
  name: 'dialog-component',
  data: () => ({
    open: true,
  }),
  watch: {
    open: function (newValue) {
      if (newValue === false) {
        this.closeModal();
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
});
